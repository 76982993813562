.lds-dual-ring {
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .lds-dual-ring2 {
    display: inline-block;
    width: 150px;
    height: 150px;
  }
  .lds-dual-ring2:after {
    content: " ";
    display: block;
    width: 134px;
    height: 134px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring2 1.2s linear infinite;
  }
  @keyframes lds-dual-ring2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }