.parallax {
    /* Set a specific height */
    min-height: 400px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position:center;
    background-image:  linear-gradient(rgba(0, 0, 0, 0.751), rgba(0, 0, 0, 0.719)), url("./Assests/isolove.jpeg");
    background-size: contain;
    background-repeat: no-repeat;
  }


